<template>
  <b-card>
    <div slot="header">
      <strong>Criar Regras de Grupos</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Regras de Grupos' }">Listar Regras de Grupo</router-link></p>

    <form v-on:submit.prevent="addRegraGrupo">
      <div class="row">
        
        <div class="col-lg-6">
          <div class="form-group">
              <label name="dataCriacao"><strong>Grupo</strong></label>
              <b-form-select v-model="regraGrupo.grp_ID" :options="osGrupos">
              </b-form-select>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
              <label name="dataCriacao"><strong>Regra</strong></label>
              <b-form-select v-model="regraGrupo.role_ID" :options="asRegras">
              </b-form-select>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Criar</button>

    </form>

  </b-card>
</template>


<script>
export default {
  data () {
    return {
      regraGrupo: {
        grp_ID: null,
        role_ID: null
      },
      osGrupos: [],
      asRegras: []
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.listaOpcoes()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    listaOpcoes: function () {
      this.listar('gp_Grupo', 'grp_mnemonico', '(grp_ativo = 1) AND (gtipo_ID = 1)').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.osGrupos.push({ value: response[i].grp_ID, text: response[i].grp_mnemonico })
          }
        },
        (response) => {}
      )
      this.listar('gp_Regra', 'role_descricao', 'role_ativo = 1').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.asRegras.push({ value: response[i].role_ID, text: response[i].role_descricao })
          }
        },
        (response) => {}
      )
    },
    addRegraGrupo: function () {
      if (this.regraGrupo.grp_ID > 0 && this.regraGrupo.role_ID > 0) {
        this.campo('gp_Grupo_Regra', 'grprole_ID', '(grp_ID = ' + this.regraGrupo.grp_ID + ') AND (role_ID = ' + this.regraGrupo.role_ID + ')').then(
          (response) => {
            if (response > 0) {
              this.$notify({
                type: 'danger',
                title: 'Essa regra já existe',
                text: 'Verifique na listagem e confira as informações'
              })
            } else {
              this.inserir('gp_Grupo_Regra', {resource: [ this.regraGrupo ]}).then(
                (response) => {},
                (response) => {}
              )
            }
          },
          (response) => {
            this.inserir('gp_Grupo_Regra', {resource: [ this.regraGrupo ]}).then(
              (response) => {},
              (response) => {}
            )
          }
        )
      } else {
        this.$notify({
          type: 'danger',
          title: 'Informe os tipos e regras corretamente',
          text: 'Confira as informações'
        })
      }
    }
  }
}
</script>
